<template>
  <div>
    <v-card elevation="2" tile>
      <v-card-title
        >Edición del Proceso de Conciliación {{ proceso.nombre }}</v-card-title
      >
      <v-divider></v-divider>
      <v-form v-model="valid">
        <v-container>
          <v-row>
            <v-col cols="12" md="6">
              <v-text-field
                v-model="proceso.nombre"
                label="Nombre del Proceso"
                :rules="
                  required.concat(
                    (v) => (v && v.length <= 100) || 'Máximo 100 caracteres'
                  )
                "
                :counter="100"
                hide-details="auto"
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="6">
              <v-text-field
                v-model="proceso.descripcion"
                label="Descripción"
                :counter="250"
                hide-details="auto"
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" md="6">
              <div
                v-if="loadingLayouts"
                class="spinner-border"
                role="status"
              ></div>
              <v-select
                v-if="!loadingLayouts"
                v-model="proceso.fuente_id"
                :items="fuentes"
                :item-text="'nombre'"
                :item-value="'id'"
                attach
                label="Fuente"
                :rules="required"
                
              ></v-select>
            </v-col>
            <v-col cols="12" md="6">
              <div
                v-if="loadingLayouts"
                class="spinner-border"
                role="status"
              ></div>
              <v-select
                v-if="!loadingLayouts"
                v-model="proceso.fuente_comparacion_id"
                :items="fuentes"
                :item-text="'nombre'"
                :item-value="'id'"
                attach
                label="Fuente de Comparación"
                :rules="required"
                
              ></v-select>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="2" md="2">
              <v-switch v-model="proceso.consulta_exclusiva" :label="`Sólo yo consulto`"></v-switch>
            </v-col>
            <v-col cols="2" md="2">
              <v-switch v-model="proceso.edita_exclusiva" :label="`Sólo yo edito`"></v-switch>
            </v-col>
            <v-col cols="2" md="2">
              <v-switch v-model="proceso.ejecuta_exclusiva" :label="`Sólo yo ejecuto`"></v-switch>
            </v-col>
            <v-col cols="2" md="2">
              <v-switch v-model="proceso.descarga_exclusiva" :label="`Sólo yo descargo`"></v-switch>
            </v-col>
            <v-col cols="2" md="2">
              <v-switch v-model="proceso.elimina_exclusiva" :label="`Sólo yo elimino`"></v-switch>
            </v-col>
          </v-row>

          <v-row>
            <v-col cols="12" md="4">
              <v-switch v-model="proceso.activo" :label="`Estado`"></v-switch>
            </v-col>
          </v-row>
        </v-container>
      </v-form>
      <v-divider></v-divider>
      <!-- <pre>{{proceso}}</pre> -->
      <v-card-actions>
        <v-spacer></v-spacer>
        <CButton color="secondary" @click="preCancelar">Cancelar</CButton>&nbsp;
        <div v-if="loadingSave" class="spinner-border" role="status"></div>
        <CButton
          v-if="!loadingSave"
          color="primary"
          class="px-4"
          @click="save()"
          :disabled="!valid"
          >Guardar</CButton
        >
      </v-card-actions>
    </v-card>

    <v-dialog v-model="dialog" max-width="420">
      <v-card>
        <v-card-title class="text-h5">
          ¿Desea cancelar la edición?
        </v-card-title>

        <v-card-text>
          Si cancela la edición los datos diligenciados no quedarán guardados.
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>

          <v-btn color="red darken-1" text @click="goBack">
            Cancelar y volver
          </v-btn>

          <v-btn color="green darken-1" text @click="dialog = false">
            Continuar la edición
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import ProcesoConciliadorService from "@/services/procesoConciliador.service";
import validationField from "@/util/validationField";
import LayoutService from "@/services/layout.service";
import ListaService from "@/services/lista.service";
import RoleService from "@/services/role.service";
import { mapState } from "vuex";

export default {
  data() {
    return {
      proceso: {
        nombre: undefined,
        descripcion: undefined,
        fuente_id: undefined,
        fuente_comparacion_id: undefined,
        consulta_exclusiva: false,
        edita_exclusiva: false,
        ejecuta_exclusiva: false,
        descarga_exclusiva: false,
        elimina_exclusiva: false,
        activo: true,
      },
      valid: false,
      rules: [
        (value) => !!value || "Requerido",
        (value) => (value && value.length >= 3) || "Mínimo 3 caracteres",
      ],
      required: [(value) => !!value || "Requerido"],
      roles: [],
      fuentes: [],
      loadingLayouts: false,
      loadingRoles: false,
      loadingSave: false,
      message: "",
      dialog: false,
    };
  },
  computed: {
    ...mapState(["permissions"]),
  },
  watch: {},
  mounted() {
    if (!this.permissions.includes("Definición de proceso de conciliador"))
      this.$router.push("/dashboard");

    if (localStorage.getItem("datos_proceso_conciliador")) {
      this.proceso = JSON.parse(
        localStorage.getItem("datos_proceso_conciliador")
      );
    }

    this.fetchLayouts("");
    // this.fetchRoles("");
  },
  methods: {
    save() {
      this.loadingSave = true;
      if (this.proceso.hasOwnProperty("id")) {
        ProcesoConciliadorService.editItem(this.proceso).then(
          (response) => {
            this.$router.push({ path: `/conciliador/procesos-conciliador` });
            console.log(response.data);
          },
          (error) => {
            this.content =
              (error.response &&
                error.response.data &&
                error.response.data.message) ||
              error.message ||
              error.toString();
          }
        );
      } else {
        ProcesoConciliadorService.newItem(this.proceso).then(
          (response) => {
            this.$router.push({ path: `/conciliador/procesos-conciliador` });
            console.log(response.data);
          },
          (error) => {
            this.content =
              (error.response &&
                error.response.data &&
                error.response.data.message) ||
              error.message ||
              error.toString();
          }
        );
      }
    },
    fetchLayouts(cadenaBusqueda) {
      this.loadingLayouts = true;
      if (cadenaBusqueda == "") cadenaBusqueda = "?activo=1";
      else cadenaBusqueda = cadenaBusqueda + "&activo=1";
      LayoutService.getItems(cadenaBusqueda).then(
        (response) => {
          this.fuentes = response.data;
          this.loadingLayouts = false;
        },
        (error) => {
          this.content =
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.toString();
          this.loadingLayouts = false;
        }
      );
    },
    fetchRoles(cadenaBusqueda) {
      this.loadingRoles = true;
      RoleService.getItems(cadenaBusqueda).then(
        (response) => {
          this.roles = response.data;
          this.loadingRoles = false;
        },
        (error) => {
          this.content =
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.toString();
          this.loadingCampos = false;
        }
      );
    },
    preCancelar() {
      this.dialog = true;
    },
    goBack() {
      this.$router.push({ path: "/conciliador/procesos-conciliador" });
    },
  },
};
</script>

<style>
</style>
