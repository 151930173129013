import api from './api';

class ProcesoConciliadorService {

  getItems(cadenaBusqueda) {
    cadenaBusqueda = cadenaBusqueda ? cadenaBusqueda : ''
    return api.get(`/api/procesos_conciliador${cadenaBusqueda}`);
  }

  getItem(id) {
    return api.get(`/api/procesos_conciliador/${id}`);
  }

  newItem(params) {
    return api.post(`/api/procesos_conciliador/`, params)
  }

  editItem(params) {
    return api.put(`/api/procesos_conciliador/${params.id}`, params)
  }

  deleteItem(procesoId) {
    return api.delete(`/api/procesos_conciliador/${procesoId}`)
  }
  
}

export default new ProcesoConciliadorService();
